import NavLink from '@/Components/NavLink';
import {getIcon} from '@/constants';
import {useAppDispatch, useAppSelector} from '@/redux/hooks';
import {resetEntireApp, toggleMobileMenu} from '@/redux/slices/appSettingSlice';
import {setLocale} from '@/redux/slices/localeSlice';
import {Dialog, Menu, Popover, Transition} from '@headlessui/react';
import {Bars3Icon, XMarkIcon} from '@heroicons/react/24/outline';
import {Link, router, usePage} from '@inertiajs/react';
import {isNull} from 'lodash';
import {ChevronDown} from 'lucide-react';
import React, {Fragment, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactSVG} from 'react-svg';

export default function (): React.ReactNode {
    const {
        props: {auth, currentLang, setting, currentRouteName},
    }: any = usePage();
    const {
        appSetting: {mobileMenuOpen},
        locale: {otherLang, dir},
    } = useAppSelector((state) => state);
    const dispatch = useAppDispatch();
    const {t} = useTranslation();

    const handleChangeLang = async () => {
        dispatch(setLocale(otherLang));
        router.visit(currentRouteName[otherLang] ?? currentRouteName, {
            preserveScroll: true,
        });
    };

    useEffect(() => {
        if (mobileMenuOpen) {
            dispatch(toggleMobileMenu());
        }
    }, [currentLang, currentRouteName]);

    return (
        <header className="sticky backdrop-blur-sm bg-white  inset-x-0 top-4 z-50 rounded-full px-6 py-2 ">
            <nav
                className="flex items-center justify-start "
                aria-label="Global"
            >
                <div className="flex sm:me-6 border-black">
                    <Link
                        href={route(`${currentLang}.frontend.home`)}
                        className="-m-1.5 p-1.5 w-20 md:w-32"
                    >
                        <span className="sr-only">{setting.name}</span>
                        <img
                            className="h-10 w-auto"
                            src={setting.thumb}
                            alt={setting.name}
                        />
                    </Link>
                </div>
                <div className="flex w-full justify-end items-center lg:hidden">
                    <button
                        type="button"
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                        onClick={() => dispatch(toggleMobileMenu())}
                    >
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <div className="hidden print:hidden lg:flex flex-1 justify-start items-center lg:gap-x-6">
                    <NavLink
                        href={route(`${currentLang}.frontend.home`)}
                        data-state={
                            route().current(`${currentLang}.frontend.home`)
                                ? `active`
                                : `none`
                        }
                        className="capitalize text-sm font-semibold leading-12 text-gray-600 "
                    >
                        {t('home')}
                    </NavLink>
                    <NavLink
                        href={route(`${currentLang}.frontend.course.index`)}
                        data-state={
                            route().current(
                                `${currentLang}.frontend.course.index`,
                            )
                                ? `active`
                                : `none`
                        }
                        className="capitalize text-sm font-semibold leading-12 text-gray-600 "
                    >
                        {t('courses')}
                    </NavLink>
                    {/* services (docs & questionnaires) */}
                    <Popover className="relative">
                        <Popover.Button
                            className={
                                'flex flex-row justify-center items-center gap-x-2 capitalize text-sm font-semibold leading-12 text-gray-600'
                            }
                        >
                            <div>{t('services')}</div>
                            <div>
                                <ChevronDown className="w-4 h-4 text-gray-700" />
                            </div>
                        </Popover.Button>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                        >
                            <Popover.Panel className="absolute  z-10 mt-5 flex w-screen max-w-max rtl:translate-x-10 ltr:-translate-x-10 px-4">
                                <div className="w-screen max-w-sm flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                                    <div className="p-4 grid grid-cols-1 gap-x-2 capitalize">
                                        <Link
                                            preserveScroll
                                            href={route(
                                                `${currentLang}.frontend.questionnaire.index`,
                                            )}
                                            className="group relative flex justify-start items-center gap-x-6 rounded-lg p-2 hover:bg-gray-50 w-full"
                                        >
                                            <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                                <ReactSVG
                                                    className="h-6 w-6 text-gray-600 group-hover:text-theme-600"
                                                    src={getIcon(`item.svg`)}
                                                />
                                            </div>
                                            <div>
                                                <Link
                                                    preserveScroll
                                                    href={route(
                                                        `${currentLang}.frontend.questionnaire.index`,
                                                    )}
                                                    className="normal-text "
                                                >
                                                    {t('universities_forms')}
                                                    <span className="absolute inset-0" />
                                                </Link>
                                            </div>
                                        </Link>
                                        <Link
                                            preserveScroll
                                            href={route(
                                                `${currentLang}.frontend.document.index`,
                                            )}
                                            className="group relative flex justify-start items-center gap-x-6 rounded-lg p-2 hover:bg-gray-50 w-full"
                                        >
                                            <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                                <ReactSVG
                                                    className="h-6 w-6 text-gray-600 group-hover:text-theme-600"
                                                    src={getIcon(`item.svg`)}
                                                />
                                            </div>
                                            <div>
                                                <Link
                                                    href={route(
                                                        `${currentLang}.frontend.document.index`,
                                                    )}
                                                    className="normal-text "
                                                >
                                                    {t('books')}
                                                    <span className="absolute inset-0" />
                                                </Link>
                                            </div>
                                        </Link>
                                        <Link
                                            preserveScroll
                                            href={route(
                                                `${currentLang}.frontend.questionnaire.index`,
                                            )}
                                            className="group relative flex justify-start items-center gap-x-6 rounded-lg p-2 hover:bg-gray-50 w-full"
                                        >
                                            <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                                <ReactSVG
                                                    className="h-6 w-6 text-gray-600 group-hover:text-theme-600"
                                                    src={getIcon(`item.svg`)}
                                                />
                                            </div>
                                            <div>
                                                <Link
                                                    preserveScroll
                                                    href={route(
                                                        `${currentLang}.frontend.course.index`,
                                                        {
                                                            group: 'online',
                                                            is_ticket: 1,
                                                        },
                                                    )}
                                                    className="normal-text "
                                                >
                                                    {t('online_consultancies')}
                                                    <span className="absolute inset-0" />
                                                </Link>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </Popover.Panel>
                        </Transition>
                    </Popover>
                    <NavLink
                        href={route(`${currentLang}.frontend.tree.index`)}
                        data-state={
                            route().current(
                                `${currentLang}.frontend.tree.index`,
                            )
                                ? `active`
                                : `none`
                        }
                        className="capitalize text-sm font-semibold leading-12 text-gray-600 "
                    >
                        {t('trees')}
                    </NavLink>
                    <NavLink
                        href={route(`${currentLang}.frontend.contactus`)}
                        data-state={
                            route().current(`${currentLang}.frontend.contactus`)
                                ? `active`
                                : `none`
                        }
                        className="capitalize text-sm font-semibold leading-12 text-gray-600 "
                    >
                        {t('contactus')}
                    </NavLink>
                </div>
                <div className={'mx-2 xl:mx-4'}>
                    <button
                        onClick={() => handleChangeLang()}
                        className="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-prim-900 hover:bg-theme-100 rounded-full"
                    >
                        <ReactSVG
                            className="h-4 w-4 text-gray-600 group-hover:text-theme-600"
                            src={getIcon('globe.svg')}
                        />
                        <div className={'capitalize hidden sm:flex'}>
                            {t(otherLang)}
                        </div>
                    </button>
                </div>

                {/* Profile dropdown */}
                {!isNull(auth) ? (
                    <Menu as="div" className="relative">
                        <Menu.Button
                            className={
                                'flex flex-row justify-evenly items-center gap-x-2 z-50'
                            }
                        >
                            <div>
                                <img
                                    src={auth.image}
                                    className="w-8 h-8 rounded-full border border-gray-200"
                                />
                            </div>
                            <div className="truncate text-gray-600 text-xs">
                                {auth.name}
                            </div>
                            <div>
                                <ChevronDown className="w-3 h-3 text-gray-600" />
                            </div>
                        </Menu.Button>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="absolute ltr:right-0 rtl:left-0 z-10 mt-2.5 w-60 origin-top-right rounded-xl bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none divide-y divide-gray-100 z-50">
                                <Menu.Item>
                                    <Link
                                        href={route(
                                            `${currentLang}.frontend.profile.course`,
                                        )}
                                        className={
                                            'block px-3 py-3 text-sm leading-6 text-prim-900 hover:bg-gray-100 capitalize'
                                        }
                                    >
                                        {`${t('my_courses')}`}
                                    </Link>
                                </Menu.Item>
                                <Menu.Item>
                                    <Link
                                        href={route(
                                            `${currentLang}.frontend.profile.document`,
                                        )}
                                        className={
                                            'block px-3 py-3 text-sm leading-6 text-prim-900 hover:bg-gray-100 capitalize'
                                        }
                                    >
                                        {`${t('my_documents')}`}
                                    </Link>
                                </Menu.Item>
                                <Menu.Item>
                                    <Link
                                        href={route(
                                            `${currentLang}.frontend.profile.questionnaire`,
                                        )}
                                        className={
                                            'block px-3 py-3 text-sm leading-6 text-prim-900 hover:bg-gray-100 capitalize'
                                        }
                                    >
                                        {`${t('my_questionnaires')}`}
                                    </Link>
                                </Menu.Item>
                                <Menu.Item>
                                    <Link
                                        href={route(
                                            `${currentLang}.frontend.profile.invoice`,
                                        )}
                                        className={
                                            'block px-3 py-3 text-sm leading-6 text-prim-900 hover:bg-gray-100 capitalize'
                                        }
                                    >
                                        {`${t('my_invoices')}`}
                                    </Link>
                                </Menu.Item>
                                {auth.isAdmin && (
                                    <Menu.Item>
                                        <Link
                                            href={route(
                                                `${currentLang}.backend.home`,
                                            )}
                                            className={
                                                'block px-3 py-3 text-sm leading-6 text-prim-900 hover:bg-gray-100 capitalize'
                                            }
                                        >
                                            {`${t('dashboard')} - (${t(auth.roles.name)})`}
                                        </Link>
                                    </Menu.Item>
                                )}
                                <Menu.Item>
                                    <Link
                                        href={route(
                                            `${currentLang}.frontend.profile.edit`,
                                            auth.id,
                                        )}
                                        className={
                                            'block px-3 py-3 text-sm leading-6 text-prim-900 hover:bg-gray-100 capitalize'
                                        }
                                    >
                                        {`${t('edit')} ${t('password')}`}
                                    </Link>
                                </Menu.Item>
                                <button
                                    className={
                                        'w-full rtl:text-right ltr:text-left px-3 py-3 text-sm leading-6 text-prim-900 hover:bg-gray-100'
                                    }
                                    onClick={() => {
                                        dispatch(resetEntireApp());
                                        router.post(
                                            route(`${currentLang}.logout`),
                                        );
                                    }}
                                >
                                    {t('logout')}
                                </button>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                ) : (
                    <div className="hidden lg:flex lg:justify-end border border-theme-700 rounded-3xl">
                        <Link
                            href={route(`${currentLang}.login`)}
                            className="text-center p-3 px-6 w-1/2 border-e border-gray-100 text-theme-700 hover:bg-theme-700 hover:text-white capitalize text-sm lg:text-normal rounded-s-3xl truncate"
                        >
                            {t('login')}
                        </Link>
                        <Link
                            href={route(`${currentLang}.register`)}
                            className="text-center p-3 px-6 w-1/2  text-theme-700 hover:bg-theme-700 hover:text-white capitalize text-sm lg:text-normal rounded-e-3xl truncate"
                        >
                            {t('new_user')}
                        </Link>
                    </div>
                )}
            </nav>
            {/* mobile */}
            <Dialog
                as="div"
                className="lg:hidden"
                open={mobileMenuOpen}
                onClose={() => dispatch(toggleMobileMenu)}
                dir={dir}
            >
                <div className="fixed inset-0 z-50" />
                <Dialog.Panel className="fixed inset-y-0 rtl:right-0 ltr:left-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                        <Link
                            preserveScroll
                            href={route(`${currentLang}.frontend.home`)}
                            className="-m-1.5 p-1.5"
                        >
                            <span className="sr-only">{setting.name}</span>
                            <img
                                className="w-auto h-10"
                                src={setting.thumb}
                                alt={setting.name}
                            />
                        </Link>
                        <button
                            type="button"
                            className="-m-2.5 rounded-md p-2.5 text-gray-700"
                            onClick={() => dispatch(toggleMobileMenu())}
                        >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="flex flex-col justify-start items-center gap-y-2 py-6">
                                <Link
                                    preserveScroll
                                    href={route(`${currentLang}.frontend.home`)}
                                    className="-mx-3 w-full rtl:text-right ltr:text-left rounded-lg px-3 py-2 text-base font-semibold leading-7 text-prim-900 hover:bg-gray-50 "
                                >
                                    {t('home')}
                                </Link>
                                <Link
                                    preserveScroll
                                    href={route(
                                        `${currentLang}.frontend.course.index`,
                                    )}
                                    className="-mx-3 w-full rtl:text-right ltr:text-left rounded-lg px-3 py-2 text-base font-semibold leading-7 text-prim-900 hover:bg-gray-50 "
                                >
                                    {t('courses')}
                                </Link>
                                <Link
                                    preserveScroll
                                    href={route(
                                        `${currentLang}.frontend.document.index`,
                                    )}
                                    className="-mx-3 w-full rtl:text-right ltr:text-left rounded-lg px-3 py-2 text-base font-semibold leading-7 text-prim-900 hover:bg-gray-50 "
                                >
                                    {t('books')}
                                </Link>
                                <Link
                                    preserveScroll
                                    href={route(
                                        `${currentLang}.frontend.questionnaire.index`,
                                    )}
                                    className="-mx-3 w-full rtl:text-right ltr:text-left rounded-lg px-3 py-2 text-base font-semibold leading-7 text-prim-900 hover:bg-gray-50 "
                                >
                                    {t('universities_forms')}
                                </Link>
                                <Link
                                    preserveScroll
                                    href={route(
                                        `${currentLang}.frontend.contactus`,
                                    )}
                                    className="-mx-3 w-full rtl:text-right ltr:text-left rounded-lg px-3 py-2 text-base font-semibold leading-7 text-prim-900 hover:bg-gray-50 "
                                >
                                    {t('contactus')}
                                </Link>
                                <Link
                                    preserveScroll
                                    href={route(
                                        `${currentLang}.frontend.terms`,
                                    )}
                                    className="-mx-3 w-full rtl:text-right ltr:text-left rounded-lg px-3 py-2 text-base font-semibold leading-7 text-prim-900 hover:bg-gray-50 "
                                >
                                    {t('the_terms_and_conditions')}
                                </Link>
                                <div className={'mx-2 xl:mx-4 w-full'}>
                                    <button
                                        onClick={() => handleChangeLang()}
                                        className="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-prim-900 hover:bg-theme-100 rounded-full"
                                    >
                                        <ReactSVG
                                            className="h-4 w-4 text-gray-600 group-hover:text-theme-600"
                                            src={getIcon('globe.svg')}
                                        />
                                        <div className={'capitalize'}>
                                            {t(otherLang)}
                                        </div>
                                    </button>
                                </div>
                            </div>
                            {isNull(auth) ? (
                                <div className="py-6">
                                    <Link
                                        preserveScroll
                                        href={route(`${currentLang}.login`)}
                                        className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-prim-900 hover:bg-gray-50"
                                    >
                                        {t('login')}
                                    </Link>
                                    <Link
                                        preserveScroll
                                        href={route(`${currentLang}.register`)}
                                        className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-prim-900 hover:bg-gray-50"
                                    >
                                        {t('register_now')}
                                    </Link>
                                </div>
                            ) : (
                                <div className="py-6">
                                    <Link
                                        preserveScroll
                                        href={route(
                                            `${currentLang}.frontend.profile.invoice`,
                                        )}
                                        className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-prim-900 hover:bg-gray-50"
                                    >
                                        {t('my_invoices')}
                                    </Link>
                                    <Link
                                        preserveScroll
                                        href={route(
                                            `${currentLang}.frontend.profile.course`,
                                        )}
                                        className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-prim-900 hover:bg-gray-50"
                                    >
                                        {t('my_courses')}
                                    </Link>
                                    <Link
                                        preserveScroll
                                        href={route(
                                            `${currentLang}.frontend.profile.document`,
                                        )}
                                        className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-prim-900 hover:bg-gray-50"
                                    >
                                        {t('my_documents')}
                                    </Link>
                                    <Link
                                        preserveScroll
                                        href={route(
                                            `${currentLang}.frontend.profile.questionnaire`,
                                        )}
                                        className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-prim-900 hover:bg-gray-50"
                                    >
                                        {t('my_questionnaires')}
                                    </Link>
                                </div>
                            )}
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </header>
    );
}
